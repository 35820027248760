import { GatsbyBrowser } from 'gatsby';

import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider';

import { AppCartProvider } from '../components/shop/cart/app-cart-provider/app-cart-provider';
import { client } from './client';

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */
export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return (
    <AppCartProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </AppCartProvider>
  );
};
