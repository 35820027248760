module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.simpel-web.de/graphql","production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"schema":{"typePrefix":"SimpelWp","perPage":50,"requestConcurrency":2,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"timeout":30000},"html":{"imageMaxWidth":2048,"imageQuality":90,"generateWebpImages":false,"useGatsbyImage":false,"gatsbyImageOptions":{"loading":"eager"},"fallbackImageMaxWidth":1024,"createStaticFiles":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"localFile":{"requestConcurrency":10,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-4RJX9E2FX0","cookieName":"SIMPEL_WEB_ANALYTICS_CONSENT","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simpel Web","short_name":"simpel-web","start_url":"/","background_color":"#ffffff","theme_color":"#232427","display":"minimal-ui","icon":"src/assets/img/manifest.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a0bb988496058e7022045c9ff0480f3"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","siteUrl":"https://simpel-web.de/","i18nextOptions":{"interpolation":{"escapeValue":false},"ns":["blog","post"]},"redirect":true,"pages":[{"matchPath":"/:lang?/blog/(.*)?","getLanguageFromPath":true},{"matchPath":"/","languages":["de"]},{"matchPath":"/404(.*)","languages":["de"]},{"matchPath":"/datenschutzerklaerung","languages":["de"]},{"matchPath":"/impressum","languages":["de"]},{"matchPath":"/demos/(.*)","languages":["de"]},{"matchPath":"/produkt-kategorie/(.*)","languages":["de"]},{"matchPath":"/produkt/(.*)","languages":["de"]}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
