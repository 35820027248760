exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-demos-food-menu-tsx": () => import("./../../../src/pages/demos/food-menu.tsx" /* webpackChunkName: "component---src-pages-demos-food-menu-tsx" */),
  "component---src-pages-demos-shop-cart-tsx": () => import("./../../../src/pages/demos/shop/cart.tsx" /* webpackChunkName: "component---src-pages-demos-shop-cart-tsx" */),
  "component---src-pages-demos-shop-checkout-index-tsx": () => import("./../../../src/pages/demos/shop/checkout/index.tsx" /* webpackChunkName: "component---src-pages-demos-shop-checkout-index-tsx" */),
  "component---src-pages-demos-shop-checkout-order-received-tsx": () => import("./../../../src/pages/demos/shop/checkout/order-received.tsx" /* webpackChunkName: "component---src-pages-demos-shop-checkout-order-received-tsx" */),
  "component---src-pages-demos-shop-index-tsx": () => import("./../../../src/pages/demos/shop/index.tsx" /* webpackChunkName: "component---src-pages-demos-shop-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-shop-category-page-tsx": () => import("./../../../src/templates/shop/category-page.tsx" /* webpackChunkName: "component---src-templates-shop-category-page-tsx" */),
  "component---src-templates-shop-product-page-tsx": () => import("./../../../src/templates/shop/product-page.tsx" /* webpackChunkName: "component---src-templates-shop-product-page-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

