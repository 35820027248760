/**
 * Internal dependencies.
 */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import fetch from 'isomorphic-fetch';

import { isBrowserAvailable } from '../components/shop/utils/helper-service';

/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have a auth token in localStorage, add it to the GraphQL request as a authorization header.
 */
export const middleware = new ApolloLink((operation, forward) => {
  let headersData: { [key: string]: string } = {
    Accept: 'application/json',
  };

  /**
   * If session data exist in local storage, set value as session header.
   */
  const isBrowser = isBrowserAvailable();
  const session = isBrowser ? localStorage.getItem('woo-session') : undefined;

  if (session !== undefined && session !== null) {
    headersData = {
      ...headersData,
      'woocommerce-session': `Session ${session}`,
    };
  }

  /**
   * If auth token exist in local storage, set value as authorization header.
   */
  const auth =
    isBrowser && localStorage.getItem('auth') !== null
      ? JSON.parse(localStorage.getItem('auth') as string)
      : undefined;
  const token = auth ? auth.authToken : undefined;

  if (token !== undefined) {
    headersData = {
      ...headersData,
      authorization: token ? `Bearer ${token}` : '',
    };
  }

  operation.setContext(() => ({
    headers: headersData,
  }));

  return forward(operation);
});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const session = headers.get('woocommerce-session');

    if (session) {
      // Remove session data if session destroyed.
      if (session === 'false') {
        localStorage.removeItem('woo-session');

        // Update session new data if changed.
      } else if (localStorage.getItem('woo-session') !== session) {
        localStorage.setItem('woo-session', headers.get('woocommerce-session'));
      }
    }

    return response;
  }),
);

// Apollo GraphQL client.
export const client = new ApolloClient({
  link: middleware.concat(
    afterware.concat(
      createHttpLink({
        uri: process.env.API_URL || `https://admin.simpel-web.de/graphql`,
        fetch,
      }),
    ),
  ),
  cache: new InMemoryCache(),
});
