import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { isBrowserAvailable } from '../../utils/helper-service';
import { Cart } from '../models/cart.model';

export type GlobalContent = [
  cart: Cart | undefined,
  setCart: Dispatch<SetStateAction<Cart | undefined>>,
];

export const AppCartContext = createContext<GlobalContent>([
  undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

interface Props {
  children: ReactNode;
}

export const AppCartProvider = ({ children }: Props) => {
  const [cart, setCart] = useState<Cart>();

  useEffect(() => {
    if (isBrowserAvailable()) {
      const cartData = localStorage.getItem('woo-next-cart');
      const parsedCart =
        cartData !== 'undefined' ? JSON.parse(cartData as string) : undefined;

      setCart(parsedCart);
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppCartContext.Provider value={[cart, setCart]}>
      {children}
    </AppCartContext.Provider>
  );
};
